<template>
  <div class="content">
    <!-- <a-table :columns="columns" :data-source="data" @change="handleChange" class="Table" /> -->
    <table border="0" class="table">
      <tr class="thead">
        <th style="width: 240px"></th>
        <th style="width: 160px"></th>
        <th colspan="3" style="text-align: center"><70 mg/dL</th>
        <th colspan="2" style="text-align: center">70-180 mg/dL</th>
        <th colspan="3" style="text-align: center">>180 mg/dL</th>
        <th colspan="2" style="text-align: center">Overall</th>
      </tr>
      <tr class="tbody1">
        <td class="">Algorithm Run / Real Time</td>
        <td>BGM</td>
        <td>
          <div>±15 mg/dL</div>
          <div>[>85%]</div>
        </td>
        <td>
          <div>±40 mg/dL</div>
          <div>[>98%]</div>
        </td>
        <td>
          <div>>180 mg/dL</div>
          <div>[0]</div>
        </td>
        <td>
          <div>±15 %</div>
          <div>[>70%]</div>
        </td>
        <td>
          <div>±40 %</div>
          <div>[>99%]</div>
        </td>
        <td>
          <div>±15 %</div>
          <div>[>80%]</div>
        </td>
        <td>
          <div>±40 %</div>
          <div>[>99%]</div>
        </td>
        <td>
          <div><70 mg/dL</div>
          <div>[0]</div>
        </td>
        <td>
          <div>±20 %</div>
          <div>[>87%]</div>
        </td>
      </tr>
      <tr class="tbody">
        <td>Real Time - EGV<br />({{ serialNumber }})</td>
        <td>
          <!-- <span style="border:1px solid red;" @click="changeTableData('SMBG')">SMBG</span>
              <span style="border:1px solid green;" @click="changeTableData('YSI')">YSI</span> -->
          <a-radio-group default-value="SMBG" button-style="solid" class="radioBtn" size="small" @change="Handlechange">
            <a-radio-button value="SMBG"> SMBG </a-radio-button>
            <a-radio-button value="YSI"> YSI </a-radio-button>
          </a-radio-group>
        </td>

        <td>
          <span style="color: black"
            >{{ iCGMTableData.col7015.rate }}%<br /><span class="small-text"
              >({{ parseInt(iCGMTableData.col7015.rangeNum) }}/{{ parseInt(iCGMTableData.col7015.allNum) }})</span
            ></span
          >
        </td>
        <td>
          <span style="color: black"
            >{{ iCGMTableData.col7040.rate }}%<br /><span class="small-text"
              >({{ parseInt(iCGMTableData.col7040.rangeNum) }}/{{ parseInt(iCGMTableData.col7040.allNum) }})</span
            ></span
          >
        </td>
        <td>
          <span class="small-text">{{ parseInt(iCGMTableData.col70180.rangeNum) }}</span>
        </td>

        <td>
          <span style="color: black"
            >{{ iCGMTableData.col17018015.rate }}%<br /><span class="small-text"
              >({{ parseInt(iCGMTableData.col17018015.rangeNum) }}/{{ parseInt(iCGMTableData.col17018015.allNum) }})</span
            ></span
          >
        </td>
        <td>
          <span style="color: black"
            >{{ iCGMTableData.col17018040.rate }}%<br /><span class="small-text"
              >({{ parseInt(iCGMTableData.col17018040.rangeNum) }}/{{ parseInt(iCGMTableData.col17018040.allNum) }})</span
            ></span
          >
        </td>

        <td>
          <span style="color: black"
            >{{ iCGMTableData.col18015.rate }}%<br /><span class="small-text"
              >({{ parseInt(iCGMTableData.col18015.rangeNum) }}/{{ parseInt(iCGMTableData.col18015.allNum) }})</span
            ></span
          >
        </td>
        <td>
          <span style="color: black"
            >{{ iCGMTableData.col18040.rate }}%<br /><span class="small-text"
              >({{ parseInt(iCGMTableData.col18040.rangeNum) }}/{{ parseInt(iCGMTableData.col18040.allNum) }})</span
            ></span
          >
        </td>
        <td>
          <span class="small-text">{{ parseInt(iCGMTableData.col18070.rangeNum) }}</span>
        </td>

        <td>
          <span style="color: black"
            >{{ iCGMTableData.co1Overall.rate }}%<br /><span class="small-text"
              >({{ parseInt(iCGMTableData.co1Overall.rangeNum) }}/{{ parseInt(iCGMTableData.co1Overall.allNum) }})</span
            ></span
          >
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
  },
]
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      data,
      serialNumber: '',
      allTableData: {},
      //表格数据
      iCGMTableData: {},
      filteredInfo: null,
      sortedInfo: null,
      columns: [
        {
          title: 'Algorithm Run / Real Time',
          dataIndex: 'name',
          key: 'name',
          width: '280px',
          sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          title: 'BGM',
          dataIndex: 'age',
          key: 'age',
          width: '140px',
          sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          title: '±15mg/dL',
          dataIndex: 'address',
          key: 'address',
        },
      ],
    }
  },
  watch: {
    data(val) {
      console.log('表格数据', val)
      this.allTableData = val
      this.iCGMTableData = val.iCGMSmbgTableData
      this.serialNumber = val.serialNumber
    },
  },
  computed: {},
  methods: {
    handleChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
    },
    clearFilters() {
      this.filteredInfo = null
    },
    clearAll() {
      this.filteredInfo = null
      this.sortedInfo = null
    },
    setAgeSort() {
      this.sortedInfo = {
        order: 'descend',
        columnKey: 'age',
      }
    },
    //切换表格数据
    changeTableData(dataType) {
      if (dataType == 'SMBG') {
        this.iCGMTableData = this.allTableData.iCGMSmbgTableData
      } else if (dataType == 'YSI') {
        this.iCGMTableData = this.allTableData.iCGMYsiTableData
      }
    },
    Handlechange(e){
        //    alert(e.target.value)
           this.changeTableData(e.target.value)
    }
  },
}
</script>
<style scoped lang="less">
.table-operations {
  margin-bottom: 16px;
}

.table-operations > button {
  margin-right: 8px;
}

/* 去掉表头背景色 */
/deep/.Table {
  .ant-table-thead > tr > th {
    background-color: #fff !important;
    border-right: 1px solid #e2e2e1;
    .ant-table-header-column {
      font-size: 16px;
      font-weight: 700;
    }
    .ant-table-column-sorter {
      i {
        color: rgb(127, 98, 173) !important ;
      }
    }
  }
}

.table {
  width: 100%;
  border: none !important;
}
.table .thead {
  background-color: #fff !important;
}
.table .thead th {
  color: #353535;
  font-size: 16px;
  font-weight: 700;
  padding: 14px 10px;
  border-right: 1px solid #e2e2e1;
  border-bottom: 1px solid #e2e2e1;
}

.table .thead th:first-child {
  border-right: none !important;
}

.tbody1,
.tbody {
  background-color: #fff !important;
}
.table .tbody1 td {
  padding: 6px 10px;
  border-right: 1px solid #e2e2e1;
  border-bottom: 1px solid #e2e2e1;
  text-align: center;
  font-weight: 700;
}
.table .tbody td {
  padding: 14px 10px;
  border-right: 1px solid #e2e2e1;
  border-bottom: 1px solid #e2e2e1;
  text-align: center;
  font-weight: 700;
}

.flex {
  display: flex;
  padding: 6px 10px;
}

/deep/.radioBtn {
  .ant-radio-button-wrapper-checked {
    background-color: rgb(127, 98, 173) !important;
    box-shadow: none !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child  {
    border-color: rgb(127, 98, 173) !important;
  }
   .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):last-child  {
    border-color: rgb(127, 98, 173) !important;
  }
}
</style>